// import { amgApi } from "@/service/axios";

import axios from "@/axios";

const toggleStatusColors = async (body) => {
    const data = await axios.put(`/api/cintillo/toggle-status`, body)
    return data;
}


export {
    toggleStatusColors
}
